import GlobalStyles from "../app/Components/IPWebsiteRedesign/GlobalStyles";
import { RobotoFontWrapper } from "../app/Components/IPWebsiteRedesign/RobotoFont";
import USPSection from "../app/Components/IPWebsiteRedesign/USPSection";
import MenuHeader from "../app/Components/Menu/MenuHeader";
import Footer from "../app/Components/Footer/Footer";
import BlueSection from "../app/Components/IPWebsiteRedesign/BlueSection";
import BulletSection from "../app/Components/IPWebsiteRedesign/BulletSection";
import CTASection from "../app/Components/IPWebsiteRedesign/CTASection";
import ClientComponent from "../app/Components/IPWebsiteRedesign/ClientComponent";
import FAQSection from "../app/Components/IPWebsiteRedesign/FAQSection";
import GreenSection from "../app/Components/IPWebsiteRedesign/GreenSection";
import HeroComponent from "../app/Components/IPWebsiteRedesign/HeroComponent";
import HumanoidSection from "../app/Components/IPWebsiteRedesign/HumanoidSection";
import NewsletterSection from "../app/Components/IPWebsiteRedesign/NewsletterSection";
import OrangeSection from "../app/Components/IPWebsiteRedesign/OrangeSection";
import ReviewSection from "../app/Components/IPWebsiteRedesign/ReviewSection";

import "../app/page.css";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import NotifyBanner from "@/app/Components/Notification/NotifyBanner";
import styled from "styled-components";
import Cookie from "@/app/Components/Cookie/Cookie";
import CesBlackRibbon from "@/app/Components/CES/CesBlackRibbon";
import CesEventPopup from "@/app/Components/CES/CesEventPopup";
import HomePopup from "@/app/Components/CESPaidReport/HomePopup";

const MainComp = styled.div`
background-color: white;
@media (min-width: 576px) and (max-width: 1400px) {
  overflow-x:hidden;
      }
      `;

export default function Home(props) {
  const router = useRouter();
  const HandleOnPageReload = async () => {
    try {
      // const url = process.env.API_URL + "/loadappvariables.html";
      const url =
        process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/loadappvariables.html";
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error updating account data");
      }

      const updatedData = await response.text();
    } catch (error) {
      console.error("Error updating account data:", error);
    }
  };

  useEffect(() => {
    HandleOnPageReload();
  }, []);

  useEffect(() => {
    checkSessionStatus();
  }, []);

  const checkSessionStatus = async () => {
    const url = router.pathname;
    const apiurl =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/validateusersession";

    const res = await fetch(apiurl, {
      method: "GET",
      credentials: "include",
    });
    const sessionStatus = await res.text();
    //validate

    if (
      sessionStatus == "valid" &&
      !(
        url.includes("/interactivestories") ||
        url.includes("/ces") ||
        url.includes("/ces-2024") ||
        url.includes("/ces-2025") ||
        url.includes("/CES-Company-Profiles") ||
        url.includes("/CES2025Reports") ||
        url.includes("/viewcesreport") ||
        (url.includes("R1VFU1RMT0dJTg==") && url.includes("designid"))
      )
    ) {
      window.location.href =
        process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/design/dashboard.html";
    }
  };
  const [messageForSucessBanner, setMessageForSucessBanner] = useState("");
  const [successBanner, setSuccessBanner] = useState(false);
  const [handleCesBanner, setHandleCesBanner] = useState(true);
  const [cesModalPopup, setCesModalPopup] = useState(false);

  useEffect(() => {
    document.body.style.margin = "0";

    if (localStorage.getItem("modalClosed2025")) {
      setCesModalPopup(false);
    } else {
      setCesModalPopup(true);
    }
  }, []);

  useEffect(() => {
    const path = router.pathname;
    if (
      path.includes("/ces-2024-report") ||
      path.includes("/ces") ||
      path.includes("/ces-2024") ||
      path.includes("/ces-2025") ||
      path.includes("/CES-Company-Profile-Details") ||
      path.includes("/CES-Company-Profiles") ||
      path.includes("/paymentterms") ||
      path.includes("/CES2025Reports")
    ) {
      setHandleCesBanner(false);
    } else {
      setHandleCesBanner(true);
    }
  }, [router.pathname]);

  return (
    <>
      <Head>
        <title>Ideapoke | AI Powered Business Research</title>
        <link
          rel="shortcut icon"
          href="/ipimages/enterprise/images/ufoant.ico"
        />
        <link
          rel="icon"
          type="image/ico"
          href="/ipimages/enterprise/images/ufoant.ico"
        />
        <link
          rel="apple-touch-icon"
          href="/ipimages/enterprise/images/ufoant.ico"
        />
        <meta
          content="Elevate your business research with Ideapoke's advanced AI platform. Transition from research to actionable data using sophisticated AI analytics and market trends."
          name="description"
        />
        <meta
          content="Ideapoke | AI Powered Business Research"
          property="og:title"
        />
        <meta
          content="Elevate your business research with Ideapoke's advanced AI platform. Transition from research to actionable data using sophisticated AI analytics and market trends."
          property="og:description"
        />
        <meta
          content="AI platform, market insights, market trends"
          property="og:keywords"
        />
        <meta
          content="https://www.ideapoke.com/ipimages/enterprise/images/Ideapoke.jpg"
          property="og:image"
        />
        <meta
          content="Ideapoke | AI Powered Business Research"
          property="twitter:title"
        />
        <meta
          content="Elevate your business research with Ideapoke's advanced AI platform. Transition from research to actionable data using sophisticated AI analytics and market trends."
          property="twitter:description"
        />
        <meta
          content="https://www.ideapoke.com/ipimages/enterprise/images/Ideapoke.jpg"
          property="twitter:image"
        />
        <meta
          content="AI platform, market insights, market trends"
          property="og:keywords"
        />

        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link
          rel="shortcut icon"
          href="/ipimages/enterprise/images/ufoant.ico"
        />
        <link
          rel="icon"
          type="image/ico"
          href="/ipimages/enterprise/images/ufoant.ico"
        />
        <link
          rel="apple-touch-icon"
          href="/ipimages/enterprise/images/ufoant.ico"
        />
      </Head>

      <RobotoFontWrapper>
        {successBanner && (
          <NotifyBanner
            setSuccessBanner={setSuccessBanner}
            successBanner={successBanner}
            BackColor="#D4FFDE"
            color="#1F936E"
            Notify={
              messageForSucessBanner
                ? messageForSucessBanner
                : "Request Demo is Submitted."
            }
          ></NotifyBanner>
        )}
        <GlobalStyles />
        <MenuHeader />
        {/* CES Related Banners */}
        {handleCesBanner && <CesBlackRibbon></CesBlackRibbon>}
        {/* {cesModalPopup && <CesEventPopup></CesEventPopup>} */}
        {cesModalPopup && <HomePopup></HomePopup>}
        {/* CES Related closing */}
        <MainComp>
          <HeroComponent />
          <Cookie></Cookie>
          <ClientComponent title="Trusted by innovative companies around the world" />
          <GreenSection />
          <BlueSection />
          {/* <OrangeSection
            setMessageForSucessBanner={setMessageForSucessBanner}
            setSuccessBanner={setSuccessBanner}
          /> */}
          <BulletSection />
          <USPSection />
          <FAQSection />
          <HumanoidSection />
          <ReviewSection />
          {/* <CTASection /> */}
          <NewsletterSection
            setMessageForSucessBanner={setMessageForSucessBanner}
            setSuccessBanner={setSuccessBanner}
          />
          <Footer></Footer>
        </MainComp>
      </RobotoFontWrapper>
    </>
  );
}
