//Library Imports
import React from "react";
import styled, { keyframes } from "styled-components";
// import { Col, Row } from "antd";
// import { Visible, Hidden } from "react-grid-system";
import { Row, Col, Visible, Hidden } from "react-grid-system";
import { Fade } from "react-awesome-reveal";


// CSS Imports
import "./global.css";
import "./Research.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { PillsTag } from "./PillsTag";
import { TransparentBtn } from "./TransparentBtn";

// Import Images
import PlaceholderBlue from "./images/PlaceholderBlue.svg";
import ArrowLeft from "./images/ArrowLeft.svg";
import { BlackBtn } from "./BlackBtn";
import { userTrack } from "../Common";

// Import Videos
// import Video3 from "./video/Video3.mp4";
import VideoGif3 from "./video/VideoGif3.gif";
// import VideoGif3 from "./video/compressedgif3.gif";
// import VideoGif3 from "./video/three.webp";

import Video3 from "./video/Video3.mp4";
import Image from "next/image";
import getConfig from "next/config";
// CSS-in-JS
const MainWrapper = styled.section`
  max-width: 1209px;
  margin: 0 auto;
  /* height: 650px; */
  height: 550px;
  background: #fff;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 866px;
  }
`;

const HeroImg = styled.div`
  position: relative;
  top: 0px;
  margin-left: -165px;
  width: 650px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  border-radius: 10px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
    margin-top: -15px;
  }
`;

const Video = styled.video`
  position: relative;
  top: 0px;
  margin-left: 0px;
  width: 555px;
  height: auto;
  border-radius: 10px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
    margin-top: -44px;
  }
`;

const Box = styled.div`
  /* margin-top: 30px; */
  margin-bottom: 100px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-bottom: 70px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 65px;
  text-align: left;
  color: var(--text-color-black);
  margin-top: 5px;
  margin-bottom: 5px;
  text-wrap: nowrap;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    text-align: center;
  }
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);
  text-wrap: wrap;
  margin-top: 30px;

  span {
    font-size: var(--size20);
    font-weight: var(--fw400);
    line-height: var(--lh20);
    text-align: left;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    text-wrap: wrap;
    text-align: center;
  }
`;

const BottomText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
    text-align: center;
  }
`;

const DoubleBtnWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 60px;
  margin-bottom: 40px;

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ArrowWrapper = styled.div`
  position: relative;
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const moveArrow = keyframes`
  0%, 100% {
    right: -74px;
  }
  50% {
    right: -84px;
  }
`;

const Img = styled.div`
  position: absolute;
  right: -74px;
  top: -64px;
  animation: ${moveArrow} 1s ease-in-out infinite;
`;

const HeroImgWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0 auto;
    width: 300px;
  }
`;

const CenterBox = styled.div`
  max-width: 1209px;
  margin: 0 auto;
`;

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block; /* Visible on mobile devices */
  }
`;

const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: block; /* Visible on desktop devices */
  }
`;

const BlueSection = React.memo(() => {
  const { publicRuntimeConfig } = getConfig();

  const HandleContact = async () => {
    await userTrack("", "Onclick of contact sales", "Platform");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Contact-Sales"
        : publicRuntimeConfig.assetPrefix + "/Contact-Sales.html";
  };
  const HandleLearnMore = async () => {
    await userTrack("", "Onclick of Learn More Research Elite", "Platform");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Research-Elite"
        : publicRuntimeConfig.assetPrefix + "/Research-Elite.html";
  };

  const videoBasePath = publicRuntimeConfig.assetPrefix || "";

  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <CenterBox>
            <Row nogutter>
              <Col
                xs={12}
                sm={6}
                md={6}
                xl={6}
                xxl={6}
                xxxl={6}
                order={{ xs: 2, sm: 1, md: 1, xl: 1, xxl: 1, xxxl: 1 }}
              >
                {/* <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                xl={{ span: 12, order: 1 }}
                xxl={{ span: 12, order: 1 }}
              > */}
                <Fade duration={1500} fraction={0.5} triggerOnce={true}>
                  <HeroImgWrapper>
                    {/* <HeroImg src={PlaceholderBlue}></HeroImg> */}
                    <Hidden xs>
                      <Video
                        width="100%"
                        height="auto"
                        //controls
                        autoPlay
                        muted
                        loop
                        playsInline
                        preload="metadata"
                      >
                        <source
                          alt={
                            "Research Elite:Powerful AI Data. Curated Insights for Planning"
                          }
                          src={videoBasePath + Video3}
                          type="video/mp4"
                        />
                      </Video>
                    </Hidden>

                    <Visible xs>
                      <HeroImg>
                        <Image
                          src={VideoGif3}
                          alt="gif3"
                          style={{
                            width: "100%",
                            height: "100%",
                            top: "200px",
                          }}
                        />
                      </HeroImg>
                    </Visible>
                  </HeroImgWrapper>
                  <MobileWrapper>
                    <DoubleBtnWrapper>
                      <BlackBtn
                        onClick={HandleContact}
                        width="217px"
                        height="56px"
                      >
                        Contact Sales
                      </BlackBtn>

                      <TransparentBtn onClick={HandleLearnMore}>
                        Learn More
                      </TransparentBtn>
                      <ArrowWrapper>
                        <Img>
                          <Image alt="ArrowLeft" src={ArrowLeft} />
                        </Img>
                      </ArrowWrapper>
                    </DoubleBtnWrapper>
                  </MobileWrapper>
                </Fade>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={6}
                xl={6}
                xxl={6}
                xxxl={6}
                order={{ xs: 1, sm: 1, md: 1, xl: 1, xxl: 1, xxxl: 1 }}
              >
                {/* <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 12, order: 2 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}
                xl={{ span: 12, order: 2 }}
                xxl={{ span: 12, order: 2 }}
              > */}
                <Box>
                  {/* <PillsTag
                    as="div"
                    width="220px"
                    height="49px"
                    backgroundcolor="#E0EDFF"
                    color="#4D5AC8"
                    className="pill-center"
                  >
                    Research <span>Elite</span>
                  </PillsTag> */}
                  <Title>Powerful AI Data.</Title>
                  <Title>
                    Curated Insights for <br />
                    Planning.
                  </Title>
                  <Text>
                    Fine-tune your research through iterations using AI Agents &
                    workflows.
                  </Text>
                  <BottomText>
                    Replace expensive Consultants with In-Depth analysis and
                    Customized Outputs for your Planning and Strategy.
                  </BottomText>

                  <DoubleBtnWrapper className="btn-hide">
                    <BlackBtn
                      onClick={HandleContact}
                      width="217px"
                      height="56px"
                    >
                      Contact Sales
                    </BlackBtn>

                    <TransparentBtn onClick={HandleLearnMore}>
                      Learn More
                    </TransparentBtn>
                    <ArrowWrapper>
                      <Img>
                        <Image alt="ArrowLeft" src={ArrowLeft} />
                      </Img>
                    </ArrowWrapper>
                  </DoubleBtnWrapper>
                </Box>
              </Col>
            </Row>
          </CenterBox>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default BlueSection;
