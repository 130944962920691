/* eslint-disable react/no-unescaped-entities */
//Library Imports
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";
import { setConfiguration } from "react-grid-system";
import getConfig from "next/config";
// import Image from "next/image";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// CSS Imports
import "./global.css";
import "./Research.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { BlackBtn } from "./BlackBtn";
import { userTrack } from "../Common";

// Import Images
import ArrowRight from "./images/ArrowRight.png";

// Import Videos
import Video1 from "./video/Video1.mp4";
import Video2025A from "./video/Video2025A.mp4";
import Video2025AGIF from "./video/Video2025AGIF.gif";
import VideoGif1 from "./video/VideoGif1.gif";
import Video2025B from "./video/Video2025B.gif";
// import VideoGif1 from "./video/compressedgif1.gif";
// import VideoGif1 from "./video/One.webp";

import Image from "next/image";

// CSS-in-JS
const CenterBox = styled.div`
  max-width: 1209px;
  margin: 0 auto;
`;

const MainWrapper = styled.section`
  height: 770px;
  background: #fff;
  position: relative;
  top: 0;

  @media screen and (max-width: 1366px) {
    height: 730px;
  }

  @media screen and (max-width: 1280px) {
    height: 730px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    height: 770px;
  }
`;

const LineWrapper = styled.div`
  position: relative;
`;

const Grid = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
  perspective: 450px;
  background-color: #fff2e3;
  position: absolute;
`;

const GridFade = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: linear-gradient(1deg, #ffffff 0%, rgba(255, 242, 227, 0) 100%);
`;

const GridLines = styled.div`
  /* Firefox-specific CSS */
  @-moz-document url-prefix() {
  }
`;

const HeroContainer = styled.div`
  position: relative;
  top: 200px;

  @media (min-width: 320px) and (max-width: 480px) {
    top: 150px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 75px;
  font-weight: var(--fw400);
  line-height: 80px;
  text-align: left;
  color: var(--text-color-black);
  margin-top: 0px;
  margin-bottom: 0px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    text-align: center;
    text-wrap: balance;
  }
`;

const SubTitle = styled.h2`
  font-family: Roboto;
  font-size: var(--size46);
  font-weight: var(--fw400);
  line-height: 73px;
  text-align: left;
  color: var(--text-color-pumpkin);
  margin-top: 0;
  margin-bottom: 0;
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    text-align: center;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw400);
  line-height: var(--lh26);
  text-align: left;
  margin-top: 22px;
  margin-bottom: 22px;
  font-display: swap;
  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;
  }
`;

const DoubleBtnWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    gap: 0;
  }
`;

const ArrowWrapper = styled.div`
  position: relative;

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const moveArrow = keyframes`
  0%, 100% {
    right: -54px;
  }
  50% {
    right: -64px;
  }
`;

const Img = styled.div`
  position: absolute;
  right: -54px;
  top: 3px;
  animation: ${moveArrow} 1s ease-in-out infinite;
`;

const HeroImg = styled.div`
  position: relative;
  top: -43px;
  margin-left: 150px;
  width: 650px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  border-radius: 10px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 63px;
  }
`;

const Video = styled.video`
  position: relative;
  top: 0px;
  border-radius: 10px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  margin-left: 71px;
  width: 585px;

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 1400px) {
    margin-left: 68px;
    width: 510px;
  }

  @media only screen and (min-width: 1440px) and (max-width: 1680px) and (min-resolution: 2dppx) {
    width: 570px;
  }
`;

const HeroImgWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0 auto;
    width: 300px;
  }
`;

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block; /* Visible on mobile devices */
  }
`;

const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: block; /* Visible on desktop devices */
  }
`;

export const metadata = {
  title: "Ideapoke | AI Powe+*red Business Research",
  description: `111Elevate your business research with Ideapoke's advanced AI platform. Transition from research to actionable data using sophisticated AI analytics and market trends.`,
  openGraph: {
    type: "website",
    url: "https://www.ideapoke.com",
    title: "11Ideapoke | AI Powered Business Research",
    description:
      "11Elevate your business research with Ideapoke's advanced AI platform. Transition from research to actionable data using sophisticated AI analytics and market trends",
    siteName: "11Ideapoke",
    images: [
      {
        url: "https://ideapoke.com/ipimages/enterprise/images/ideapoke-logo-2024.jpg",
      },
    ],
  },
  twitter: {
    card: "summary_large_image",
    site: "https://www.ideapoke.com",
    creator: "@creator",
    images:
      "https://ideapoke.com/ipimages/enterprise/images/ideapoke-logo-2024.jpg",
  },
  keywords: ["AI platform", "Market insights", "AI analytics", "market trends"],
};
const { publicRuntimeConfig } = getConfig();
const videoBasePath = publicRuntimeConfig.assetPrefix || "";

const HeroComponent = React.memo(() => {
  // const HandleFreeTrail = async () => {
  //   await userTrack("", "Onclick of Get started for free", "Platform");
  //   window.location.href =
  //     process.env.NODE_ENV == "development"
  //       ? "/Get-Started"
  //       : publicRuntimeConfig.assetPrefix + "/Get-Started.html";
  // };

  const HandleFreeTrail = async () => {
    await userTrack("", "Onclick of Schedule a demo - Home Page", "Platform");

    // Encode UTM source using Base64
    const utmSource = "home-page-schedule-demo-button-the-future-of-research-is-here";
    const encodedUtmSource = window.btoa(utmSource);

    // Store encoded UTM source in localStorage
    localStorage.setItem("utm-source", encodedUtmSource);
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Schedule-Demo"
        : publicRuntimeConfig.assetPrefix + "/Schedule-Demo.html";
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|iPad|iPhone|iPod/.test(userAgent)) {
      setIsMobile(true);
      document.body.classList.add("mobile");
    } else {
      setIsMobile(false);
      document.body.classList.remove("mobile");
    }
  }, []);
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <LineWrapper>
            <Grid>
              <GridFade />
              <GridLines />
            </Grid>
          </LineWrapper>
          <CenterBox>
            <HeroContainer>
              <Container>
                <Row>
                  <Col xl={6} lg={6} sm={6} xs={12}>
                    <ArrowWrapper>
                      <Img>
                        <Image alt="Moving Arrow" src={ArrowRight}></Image>
                      </Img>
                    </ArrowWrapper>
                    <Title>The future of research is here.</Title>
                    <SubTitle>Powered by AI.</SubTitle>
                    <Text>
                      With Ideapoke's AI Business Research platform, you can
                      rapidly move from <strong>Research</strong> to{" "}
                      <strong>Results</strong>.
                    </Text>
                    <DoubleBtnWrapper className="btn-hide">
                      <BlackBtn
                        onClick={HandleFreeTrail}
                        width="234px"
                        height="56px"
                      >
                        Schedule a demo
                      </BlackBtn>
                    </DoubleBtnWrapper>
                  </Col>
                  <Col xl={6} lg={6} sm={6} xs={12}>
                    <HeroImgWrapper>
                      {/* {!isMobile ? ( */}
                        <Hidden xs>
                          <Video
                            id="video11111111111111111"
                            width="100%"
                            height="auto"
                            //controls
                            autoPlay
                            muted
                            loop
                            alt={`The future of research is here. Powered by AI.`}
                            playsInline
                            preload="metadata"
                          >
                            <source
                              src={videoBasePath + Video2025A}
                              type="video/mp4"
                            />
                          </Video>
                        </Hidden>
                      {/* ) : ( */}
                        <Visible xs>
                          <HeroImg>
                            <Image
                              src={Video2025AGIF}
                              quality={10}
                              style={{ width: "100%", height: "100%" }}
                              alt="gif1-image"
                            />
                          </HeroImg>
                        </Visible>
                      {/* )} */}
                    </HeroImgWrapper>

                    <MobileWrapper>
                      <DoubleBtnWrapper>
                        <BlackBtn
                          onClick={HandleFreeTrail}
                          width="234px"
                          height="56px"
                        >
                          Schedule a demo
                        </BlackBtn>
                      </DoubleBtnWrapper>
                    </MobileWrapper>
                  </Col>
                </Row>
              </Container>
            </HeroContainer>
          </CenterBox>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default HeroComponent;
