//Library Imports
import React from "react";
import styled, { keyframes } from "styled-components";
// import { Col, Row } from "antd";
import { Row, Col } from "react-grid-system";

// CSS Imports
import "./global.css";
import "./Research.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { BlackBtn } from "./BlackBtn";

// Import Images
import ArrowRight from "./images/ArrowRight.png";
import IconPlaceholder from "./IconPlaceholder";
import BlockIcon from "./images/BlockIcon.svg";
import CircleAIIcon from "./images/CircleAIIcon.svg";
import PeopleIcon from "./images/PeopleIcon.svg";
import { userTrack } from "../Common";
import Image from "next/image";
import getConfig from "next/config";

// CSS-in-JS
const MainWrapper = styled.section`
  /* width: 1684px; */
  max-width: 1684px;
  margin: 0 auto;
  height: 750px;
  /* border: 1px solid red; */
  background: #fff;
  overflow-x: hidden;
  /* margin-right:10px; */

  @media screen and (max-width: 1366px) {
    height: 830px;
  }

  @media screen and (max-width: 1280px) {
    height: 830px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    height: 1442px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: left;
  color: var(--text-color-black);
  margin-top: 100px;
  margin-bottom: 25px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    margin-top: 60px;
    text-align: center;
  }
`;

const Tag = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh26);
  text-align: left;
  color: var(--text-color-dark-liver);
  margin-bottom: 25px;

  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw500);
  line-height: 26px;
  text-align: left;
  color: var(--text-color-dark-liver);
  margin-top: 30px;
  margin-bottom: 30px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: var(--size18);
    text-align: center;
    padding: 10px;
  }
`;

const Box = styled.div`
  margin-left: 60px;
  margin-top: 75px;

  @media (min-width: 320px) and (max-width: 480px) {
    /* margin-left: 21px; */
    width: 325px;
    margin: 0 auto;
    margin-top: 75px;
  }
`;

const ArrowWrapper = styled.div`
  position: relative;

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const moveArrow = keyframes`
  0%, 100% {
    right: -40px;
  }
  50% {
    right: -50px;
  }
`;

const Img = styled.div`
  position: absolute;
  right: -45px;
  top: 139px;
  animation: ${moveArrow} 1s ease-in-out infinite;
`;

const IconTitle = styled.p`
  font-family: Roboto;
  /* font-size: var(--size24); */
  font-size: 22px;
  font-weight: var(--fw400);
  line-height: 28px;
  text-align: left;
  color: var(--text-color-chinese-black);
  margin-top: 35px;
  margin-bottom: 20px;
  width: 400px;
  text-wrap: wrap;
  @media (min-width: 576px) and (max-width: 1400px) {
    width: 300px;
    font-size: var(--size20);
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 325px;
    text-align: center;
  }
`;

const IconText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw400);
  line-height: 26px;
  text-align: left;
  color: var(--text-color-dark-liver);
  width: 300px;
  @media (min-width: 576px) and (max-width: 1400px) {
    width: 210px;
    font-size: var(--size15);
    font-size: 20px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 325px;
    text-align: center;
  }
`;

const NegativeMargin = styled.div`
  margin-top: -40px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 0px;
  }
`;

const CenterBox = styled.div`
  max-width: 1209px;
  margin: 0 auto;
`;

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block; /* Visible on mobile devices */
  }
`;

const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: block; /* Visible on desktop devices */
  }
`;

const BlackButtonWrap = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    width: 325px;
    margin: 0 auto;
  }
`;

const IconWrap = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    width: 330px;
    display: flex;
    justify-content: center;
  }
`;

const BulletSection = React.memo(() => {
  const { publicRuntimeConfig } = getConfig();

  const LearnmorecapabilitiesHandler = async () => {
    await userTrack(
      "",
      "Onclick of Learn more about our capabilities",
      "Platform"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/AI-Business-Research-Platform"
        : publicRuntimeConfig.assetPrefix +
          "/AI-Business-Research-Platform.html";
  };
  //
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <CenterBox>
            <Row nogutter>
              <Col xs={12} sm={6} md={6} xl={6} xxl={6} xxxl={6}>
                {/* <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                xl={{ span: 12, order: 1 }}
                xxl={{ span: 12, order: 1 }}
              > */}
                <ArrowWrapper>
                  <Img>
                    <Image alt="ArrowRight" src={ArrowRight} />
                  </Img>
                </ArrowWrapper>

                <Title>
                  Next Gen Research. <br /> With Generative AI.
                </Title>

                <Tag>Forget Generic AI tools!</Tag>
                <Text>
                  Our AI models, trained on a decade of real-world data can
                  provide the kind of insights researchers & strategists want.
                </Text>
                <BlackButtonWrap>
                  <BlackBtn
                    onClick={LearnmorecapabilitiesHandler}
                    width="336px"
                    height="56px"
                  >
                    Learn more about our capabilities
                  </BlackBtn>
                </BlackButtonWrap>
              </Col>
              <Col xs={12} sm={6} md={6} xl={6} xxl={6} xxxl={6}>
                {/* <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 12, order: 2 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}
                xl={{ span: 12, order: 2 }}
                xxl={{ span: 12, order: 2 }}
              > */}
                <Box>
                  <Row>
                    <Col xs={12} sm={6} md={6} xl={6} xxl={6} xxxl={6}>
                      {/* <Col
                      xs={{ span: 24, order: 1 }}
                      sm={{ span: 14, order: 1 }}
                      md={{ span: 14, order: 1 }}
                      lg={{ span: 14, order: 1 }}
                      xl={{ span: 14, order: 1 }}
                      xxl={{ span: 14, order: 1 }}
                    > */}
                      <IconWrap>
                        <IconPlaceholder src={BlockIcon} bgColor="#f6c143" />
                      </IconWrap>
                      <IconTitle>Any Research. One Platform</IconTitle>
                      <IconText>
                        Go from Broad search to Deep Dives all on one platform.
                      </IconText>
                    </Col>
                    <Col xs={12} sm={6} md={6} xl={6} xxl={6} xxxl={6}>
                      {/* <Col
                      xs={{ span: 24, order: 1 }}
                      sm={{ span: 10, order: 1 }}
                      md={{ span: 10, order: 1 }}
                      lg={{ span: 10, order: 1 }}
                      xl={{ span: 10, order: 1 }}
                      xxl={{ span: 10, order: 1 }}
                    > */}
                      <IconWrap>
                        <IconPlaceholder src={CircleAIIcon} bgColor="#baea86" />
                      </IconWrap>
                      <IconTitle>AI meant for Research</IconTitle>
                      <IconText>
                        With AI algorithms trained on over a million
                        customer-analyst interactions and 1000+ research
                        analyses, our outputs meet the expectations of business
                        researchers.
                      </IconText>
                    </Col>
                    <Col xs={12} sm={6} md={6} xl={6} xxl={6} xxxl={6}>
                      {/* <Col
                      xs={{ span: 24, order: 1 }}
                      sm={{ span: 12, order: 1 }}
                      md={{ span: 12, order: 1 }}
                      lg={{ span: 12, order: 1 }}
                      xl={{ span: 12, order: 1 }}
                      xxl={{ span: 12, order: 1 }}
                    > */}
                      <IconWrap>
                        <NegativeMargin>
                          <IconPlaceholder src={PeopleIcon} bgColor="#a2befb" />
                        </NegativeMargin>
                      </IconWrap>
                      <IconTitle>Collaborative Research</IconTitle>
                      <IconText>
                        Our Research Experts with a combined experience of over
                        100 years support you through your research to help you
                        shape winning strategies.
                      </IconText>
                    </Col>

                    {/* <Col xl={7} lg={7} sm={7} xs={12}></Col> */}
                    {/* <Col xl={5} lg={5} sm={5} xs={12}>
                      
                    </Col> */}

                    {/* <Col xl={6} lg={6} sm={6} xs={12}>
                      
                    </Col> */}
                  </Row>
                </Box>
              </Col>
            </Row>
          </CenterBox>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default BulletSection;
