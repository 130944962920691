//Library Imports
import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

// CSS Imports
import "./global.css";

// CSS-in-JS
const MainWrapper = styled.section`
  max-width: 1209px;
  margin: 0 auto;
  height: 625px;
  background: #fff;

  @media screen and (max-width: 1366px) {
    height: 520px;
  }

  @media screen and (max-width: 1280px) {
    height: 520px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    height: 1130px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size45);
  font-weight: var(--fw400);
  line-height: 42px;
  text-align: center;
  color: var(--text-color-black);
  /* width: 1013px; */
  /* border: 1px solid red; */
  margin: 0 auto;
  margin-bottom: 70px;
  margin-top: 80px;

  @media screen and (max-width: 1366px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 1280px) {
    margin-top: 0px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 55px;
    width: 330px;
    margin-top: 107px;
  }
`;

const CenterBox = styled.div`
  max-width: 1209px;
  margin: 0 auto;
`;

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import ClientCarousel from "./ClientCarousel";

const ClientComponent = React.memo(({ title }) => {
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <CenterBox>
            <Row>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 24, order: 1 }}
                lg={{ span: 24, order: 1 }}
                xl={{ span: 24, order: 1 }}
                xxl={{ span: 24, order: 1 }}
              >
                <Title>{title}</Title>
              </Col>
              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 24, order: 2 }}
                lg={{ span: 24, order: 2 }}
                xl={{ span: 24, order: 2 }}
                xxl={{ span: 24, order: 2 }}
              >
                <ClientCarousel />
              </Col>
            </Row>
          </CenterBox>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default ClientComponent;
