import React from 'react'
import styled from 'styled-components'
import PopupHeaderImage from "./images/PopupHeaderImage.svg"


const PopupHeaderWrapper = styled.div`
    background-image: url(${PopupHeaderImage.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px){
        height: 125px;
        background-size: contain;
        background-color: #3c62ae;
    }
`

const PopupHeader = styled.h1`
    margin: 0;
    color: #FFF;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; 
    text-align: center;
    width: 450px;

    @media screen and (max-width: 767px){
        font-size: 24px;
        width: 300px;
        line-height: 30px;
    }
`


const HomePopupHeader = (props) => {
    return (
        <div>
            <PopupHeaderWrapper>
                <PopupHeader>{props.text}</PopupHeader>
            </PopupHeaderWrapper>
        </div>
    )
}

export default HomePopupHeader