import React, { useState } from "react";
import styled from "styled-components";
import Logo from "./images/cescta_combologo.svg";
import { P } from "../CommonStyle";
import Button from "../Button";
import { userTrack } from "../Common";
import Image from "next/image";
import { Row, Col, Visible, Hidden } from "react-grid-system";

const MainWrapper = styled.div`
  /* background-color: #000;
  position: absolute;
  width: -webkit-fill-available;
  top: 80px;
  z-index: 1;
  padding: 4px; */
  /* padding-top: 4px; */

  width: -webkit-fill-available;
  background-color: #000000;
  /* position: fixed; */
  position: absolute;
  top: 80px;
  z-index: 1;
  padding: 4px;
  /* border: 1px solid red; */

  @-moz-document url-prefix() {
    width: -moz-available;
    /* width: 100%; */
  }

  @media screen and (max-width: 1280px) {
    /* padding-bottom: 0;
    top: 76px; */
    /* padding-top: 5px; */
  }
`;
const CenterBox = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center;
  gap: 45px;
  border: 10px solid red; */

  width: 850px;
  margin: 0 auto;
  /* border: 1px solid red; */

  /* @media screen and (max-width: 767px) {
    gap: 1px;
  } */

  @media screen and (max-width: 1366px) {
    width: 823px;
  }

  @media screen and (max-width: 1280px) {
    width: 823px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    /* width: fit-content; */
    width: -webkit-fill-available;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
const Img = styled.div`
  position: relative;
  top: 4px;
  @media screen and (max-width: 767px) {
    /* width: 74%; */
    width: fit-content;
  }
`;
const LgScreen = styled.div`
  @media screen and (max-width: 1280px) {
    position: relative;
  }
  @media screen and (max-width: 767px) {
    left: 25px;
    position: inherit;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
  cursor: auto;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  letter-spacing: 0.5px;
  text-align: center;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    position: relative;
    left: 13px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: 320px) and (max-width: 480px) {
    /* justify-content: center; */
  }
`;

const TextWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    width: 165px;
    margin: 0 auto;
  }
`;

const KnowMoreBtn = styled.button`
  border: none;
  border: 1px solid #454545;
  color: #454545;
  background: #fff;
  opacity: 1;
  width: 108px;
  height: 49px;
  cursor: pointer;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */

  &:hover {
    border: 1px solid #454545;
    color: #454545;
    background: #f3e4d2;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 6px 9px;
    height: 41px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default function CesBlackRibbon() {
  const [more, setMore] = useState(false);
  const knowmore = () => {
    userTrack("", "Clicked on Know more from Banner", "Ideapoke website");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/ces-2025.html";
  };
  return (
    <div>
      <MainWrapper>
        <CenterBox>
          <Row nogutter align="center">
            <Col
              xs={2}
              sm={2}
              md={2}
              xl={2}
              xxl={2}
              xxxl={2}
              order={{ xs: 1, sm: 1, md: 1, xl: 1, xxl: 1, xxxl: 1 }}
            >
              <ImageWrapper>
                <Img>
                  <Image src={Logo} alt="Logo" layout="intrinsic" />
                </Img>
              </ImageWrapper>
            </Col>
            <Col
              xs={6}
              sm={8}
              md={8}
              xl={8}
              xxl={8}
              xxxl={8}
              order={{ xs: 1, sm: 1, md: 1, xl: 1, xxl: 1, xxxl: 1 }}
            >
              <TextWrapper>
                <Text>The Ideapoke CES 2025 Report is here!</Text>
              </TextWrapper>
            </Col>

            <Col
              xs={4}
              sm={2}
              md={2}
              xl={2}
              xxl={2}
              xxxl={2}
              order={{ xs: 1, sm: 1, md: 1, xl: 1, xxl: 1, xxxl: 1 }}
            >
              <ButtonWrapper>
                <KnowMoreBtn onClick={knowmore}>Know more</KnowMoreBtn>
                {/* <Button
                  variant="solidWhite"
                  text="Know more"
                  width="108px"
                  height="49px"
                  Lgheight="35px"
                  onClick={knowmore}
                ></Button> */}
              </ButtonWrapper>
            </Col>
          </Row>

          {/* <Img>
            <Image src={Logo} alt="Logo" layout="intrinsic" />
          </Img> */}
          {/* <div>
            <P
              text="The Ideapoke CES 2025 Report is here!"
              fontSize="30px"
              LgFontSize="27px"
              fontWeight="700"
              color="#fff"
              letterSpacing="0.5px"
              mobFontSize="14px"
              mobPosition="relative"
              mobRight="35px"
            ></P>
          </div> */}
          {/* <LgScreen>
            <Button
              variant="solidWhite"
              text="Know more"
              width="108px"
              height="49px"
              Lgheight="35px"
              onClick={knowmore}
            ></Button>
          </LgScreen> */}
        </CenterBox>
      </MainWrapper>
    </div>
  );
}
