//Library Imports
import React from "react";
import styled, { keyframes } from "styled-components";
// import { Col, Row } from "antd";
import { Row, Col, Visible, Hidden } from "react-grid-system";
// import { Visible, Hidden } from "react-grid-system";
import { Fade } from "react-awesome-reveal";

// CSS Imports
import "./global.css";
import "./Research.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { PillsTag } from "./PillsTag";
import { TransparentBtn } from "./TransparentBtn";
//import { MagicBlackBtn } from "./MagicBlackBtn";
import { OrangePillsBtn } from "./OrangePillsBtn";
import { userTrack } from "../Common";

// Import Images
import YellowArrow from "./images/YellowArrow.svg";
import ArrowLeft from "./images/ArrowLeft.svg";
import ResearchAsstImage from "./images/ResearchAsstImage.svg";


// Import Videos
import Video2 from "./video/Video2.mp4";
import VideoGif2 from "./video/VideoGif2.gif";
// import VideoGif2 from "./video/compressedgif2.gif";
// import VideoGif2 from "./video/two.webp";

import Image from "next/image";
import getConfig from "next/config";
import { BlackBtn } from "./BlackBtn";

// CSS-in-JS
const MainWrapper = styled.section`
  margin: 0 auto;
  height: 500px;
  background: #fff;
  /* margin-bottom: 100px; */
  margin-bottom: 40px;
  max-width: 1209px;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 785px;
  }
`;

const HeroImg = styled.div`
  /* position: relative;
  top: 0px;
  margin-left: 150px;
  width: 400px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  border-radius: 10px; */

  position: relative;
  top: 28px;
  margin-left: 72px;
  width: 550px;

  @media screen and (max-width: 1366px) {
    width: 520px;
  }

  @media screen and (max-width: 1280px) {
    width: 520px;
  }

  /* @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
  } */
  /* @media (min-width: 576px) and (max-width: 1400px) {
    width: 650px;
  } */
`;

const Video = styled.video`
  position: relative;
  /* top: -43px; */
  top: 22px;
  border-radius: 10px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  margin-left: 91px;
  width: 530px;

  @media (min-width: 576px) and (max-width: 1400px) {
    margin-left: 82px;
    width: 510px;
    top: 0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const Box = styled.div`
  margin-bottom: 100px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 160px;
    margin-bottom: 9px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 83px;
  text-align: left;
  color: var(--text-color-black);
  margin-top: 10px;
  margin-bottom: 10px;
  text-wrap: nowrap;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    text-align: center;
  }
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);
  text-wrap: nowrap;

  span {
    font-size: var(--size20);
    font-weight: var(--fw400);
    line-height: var(--lh20);
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    text-wrap: wrap;
    text-align: center;
  }
`;

const Text1 = styled.div`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);
  text-wrap: nowrap;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 16px;
    text-wrap: wrap;
    line-height: 20px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 15px;
  margin-bottom: 15px;

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
  }
`;

const ArrowImg = styled.div`
  position: relative;

  @media (min-width: 320px) and (max-width: 480px) {
    top: 10px;
  }
`;

const BottomText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);

  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;
  }
`;

const DoubleBtnWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;

  @-moz-document url-prefix() {
    margin-top: 80px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MagicBtnWrapper = styled.div`
  position: relative;
`;

const ExtendedOrangePillsBtn = styled(OrangePillsBtn)`
  position: absolute;
  right: 5px;
  top: -21px;
`;

const ArrowWrapper = styled.div`
  position: relative;
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const moveArrow = keyframes`
  0%, 100% {
    right: -74px;
  }
  50% {
    right: -84px;
  }
`;

const Img = styled.div`
  position: absolute;
  right: -74px;
  top: -64px;
  animation: ${moveArrow} 1s ease-in-out infinite;
`;

const HeroImgWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0 auto;
    width: 300px;
  }
`;

const CenterPills = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    justify-content: center;
    display: flex;
  }
`;

const CenterBox = styled.div`
  max-width: 1209px;
  margin: 0 auto;
`;

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block; /* Visible on mobile devices */
  }
`;

const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: block; /* Visible on desktop devices */
  }
`;

const HeroImg1 = styled.div`
  position: relative;
  top: 0px;
  margin-left: -70px;
  width: 580px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 300px;
    margin: 0 auto;
    /* margin-top: 40px; */
  }

  /* @media screen and (max-width: 767px) {
    width: 100%;
    position: initial;
    margin-left: auto;
  } */
`;

const GreenSection = React.memo(() => {
  const { publicRuntimeConfig } = getConfig();

  // const HandleResearchAssistantClick = async () => {
  //   await userTrack("", "Onclick of Research Assistant", "Platform");
  //   window.location.href = "/research-assistant/AI-Research-Assistant.html";
  // };

  const HandleFreeTrail = async () => {
    await userTrack("", "Onclick of Schedule a demo - Home Page", "Platform");
    // Encode UTM source using Base64
    const utmSource = "home-page-schedule-demo-button-get-ai-to-work-for-you";
    const encodedUtmSource = window.btoa(utmSource);

    // Store encoded UTM source in localStorage
    localStorage.setItem("utm-source", encodedUtmSource);
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Schedule-Demo"
        : publicRuntimeConfig.assetPrefix + "/Schedule-Demo.html";
  };

  const HandleLearnMoreClick = async () => {
    await userTrack(
      "",
      "Onclick of Learn More for Research Assistant",
      "Platform"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Research-Assistant"
        : publicRuntimeConfig.assetPrefix + "/Research-Assistant.html";
  };

  const videoBasePath = publicRuntimeConfig.assetPrefix || "";

  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <CenterBox>
            <Row nogutter>
              <Col xs={12} sm={6} md={6} xl={6} xxl={6} xxxl={6}>
                {/* <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                xl={{ span: 12, order: 1 }}
                xxl={{ span: 12, order: 1 }}
              > */}
                <Box>
                  {/* <CenterPills>
                    <PillsTag
                      as="div"
                      width="250px"
                      height="49px"
                      backgroundcolor="#dcfce7"
                      color="#15803d"
                    >
                      Research <span>Assistant</span>
                    </PillsTag>
                  </CenterPills> */}
                  <Title>Get AI to work for you!</Title>
                  <Text>
                    Explore any theme with{" "}
                    <span>AI that's purpose built for Business Research.</span>
                  </Text>
                  <TextWrap>
                    <Text1>Choose a research activity</Text1>{" "}
                    <ArrowImg>
                      <Image
                        priority={true}
                        alt="YellowArrow"
                        src={YellowArrow}
                      />
                    </ArrowImg>
                    <Text1>Enter topic</Text1>{" "}
                    <ArrowImg>
                      <Image
                        priority={true}
                        alt="YellowArrow"
                        src={YellowArrow}
                      />
                    </ArrowImg>
                    <Text1>Get usable results in minutes</Text1>
                    <br />
                    <br />
                    <br />
                  </TextWrap>
                  {/* <BottomText>
                    Enrich Your Research with Expert-Validated Results and
                    Comprehensive Sourcing of data.
                  </BottomText> */}
                  <DoubleBtnWrapper className="btn-hide">
                    <MagicBtnWrapper>
                      <BlackBtn
                        onClick={HandleFreeTrail}
                        width="234px"
                        height="56px"
                      >
                        Schedule a demo
                      </BlackBtn>
                      {/* <MagicBlackBtn
                        onClick={HandleResearchAssistantClick}
                        width="239px"
                        height="56px"
                      >
                        Research Assistant
                      </MagicBlackBtn> */}
                      {/* <ExtendedOrangePillsBtn>Try Now</ExtendedOrangePillsBtn> */}
                    </MagicBtnWrapper>
                    <TransparentBtn onClick={HandleLearnMoreClick}>
                      Learn More
                    </TransparentBtn>
                    <ArrowWrapper>
                      <Img>
                        <Image alt="ArrowLeft" src={ArrowLeft}></Image>
                      </Img>
                    </ArrowWrapper>
                  </DoubleBtnWrapper>
                </Box>
              </Col>
              <Col xs={12} sm={6} md={6} xl={6} xxl={6} xxxl={6}>
                {/* <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 12, order: 2 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}
                xl={{ span: 12, order: 2 }}
                xxl={{ span: 12, order: 2 }}
              > */}
                <Fade duration={1500} fraction={0.5} triggerOnce={true}>
                  <HeroImgWrapper>
                    {/* <Hidden xs>
                      <Video
                        width="100%"
                        height="auto"
                        autoPlay
                        muted
                        loop
                        playsInline
                        preload="metadata"
                      >
                        <source src={videoBasePath + Video2} type="video/mp4" />
                      </Video>
                    </Hidden> */}

                    {/* <Visible xs>
                      <HeroImg>
                        <Image
                          priority={true}
                          src={VideoGif2}
                          style={{
                            width: "100%",
                            height: "100%",
                            
                          }}
                          alt="gif2-image"
                        />
                      </HeroImg>
                    </Visible> */}

                    <DesktopWrapper>
                      <HeroImg>
                        <Image
                          // width={580}
                          // height={403}
                          style={{
                            width: "100%",
                            height: "auto",
                            //boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
                            boxShadow:
                              "0 11px 40px -5px rgba(75, 32, 19, 0.19)",
                            borderRadius: "10px",
                          }}
                          src={ResearchAsstImage}
                          alt="ResearchAsstImage"
                        />
                      </HeroImg>
                    </DesktopWrapper>
                    <MobileWrapper>
                      <HeroImg1>
                        <Image
                          style={{
                            width: "100%",
                            height: "auto",
                            boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
                          }}
                          src={ResearchAsstImage}
                          alt="ResearchAsstImage"
                        />
                      </HeroImg1>
                    </MobileWrapper>
                  </HeroImgWrapper>
                  <MobileWrapper>
                    <DoubleBtnWrapper>
                      <MagicBtnWrapper>
                        <BlackBtn
                          onClick={HandleFreeTrail}
                          width="234px"
                          height="56px"
                        >
                          Schedule a demo
                        </BlackBtn>
                        {/* <MagicBlackBtn
                        onClick={HandleResearchAssistantClick}
                        width="239px"
                        height="56px"
                      >
                        Research Assistant
                      </MagicBlackBtn> */}
                        {/* <ExtendedOrangePillsBtn>Try Now</ExtendedOrangePillsBtn> */}
                      </MagicBtnWrapper>
                      <TransparentBtn onClick={HandleLearnMoreClick}>
                        Learn More
                      </TransparentBtn>
                      <ArrowWrapper>
                        <Img>
                          <Image alt="ArrowLeft" src={ArrowLeft}></Image>
                        </Img>
                      </ArrowWrapper>
                    </DoubleBtnWrapper>
                  </MobileWrapper>
                </Fade>
              </Col>
            </Row>
          </CenterBox>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default GreenSection;
