import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import ReactModal from 'react-modal'
import HomePopupHeader from './HomePopupHeader';
import CESLogoLarge from "./images/CESLogoLarge.svg";
import "./HomePopup.css"
import { userTrack } from '../Common';
import Cookies from 'js-cookie';
import Image from 'next/image';


const ButtonWrapper = styled.div`
    padding-bottom:20px;
    /* margin-top: 10px; */
    @media (min-width: 320px) and (max-width: 480px) {
        margin-top: 10px;
  }
`


const CESLogoImg = styled(Image)`
  margin-top: 30px;
  position: relative;
  margin-bottom: 20px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 30px;
    width: 250px;
  }

`
const PopupContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 400px;
    margin: 0 auto;

    @media (min-width: 320px) and (max-width: 480px) {
        width: 345px;
    }

`

const PopupTextWrapper = styled.div`
    color: #000;
    font-family: Roboto;
    width: 440px;
    @media screen and (max-width: 767px){
        width: 324px;
        margin: 0 auto;
        line-height: 27px;
    }

`

const PopupText = styled.p`
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 10px;
    @media screen and (max-width: 767px){
        line-height: 25px;
    }

`




const ButtonOrangeFill = styled.button`
    border: 1px solid rgb(255, 118, 36);
    background: rgb(255, 118, 36);
    opacity: 1;
    width: auto;
    /* width: 250px; */
    height: 55px;
    cursor: pointer;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    padding: 8px 12px;
    color: #ffffff;

    &:hover{
        border: 1px solid rgb(205, 89, 0);        
        color: rgb(255, 255, 255);
        background: rgb(205, 89, 0);
    }

    @media (min-width: 320px) and (max-width: 480px) {
        width: 200px;
        height: 50px;
        font-size: 16px;
  }
`

const PopupTextSpan = styled.span`
    color: #3C62AE;
    font-weight: 800;

`



const HomePopup = (props) => {
    const [isOpen, setIsOpen] = useState(true);
    const handleClose = () => {
        localStorage.setItem("modalClosed2025", true)
        Cookies.set('NewModle', 'true');
        setIsOpen(false);
    };


    const HandleShowReports = () => {
        userTrack(`${localStorage.storedEmail}`, "Clicked on Clicked here to know more about CES Event", "Ideapoke website");
        localStorage.setItem("modalClosed2025", true)
        Cookies.set('NewModle', 'true');
        setIsOpen(false);
        // window.location.href = process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/CES2024Reports.html"
        window.location.href = process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/ces-2025.html"
    }







    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        return () => {
            // Cleanup function to revert the style when the component unmounts
            document.body.style.overflow = 'visible';
        };
    }, [isOpen]);
    return (
        <div>
            <ReactModal
                isOpen={isOpen}
                onRequestClose={() => handleClose()}
                ariaHideApp={false}
                className="home-popup"
                style={{ marginTop: "100px" }}
            >
                <div className="close-home-popup" onClick={() => handleClose()}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.01689 1.01689C1.34785 0.685932 1.79673 0.5 2.26478 0.5C2.73283 0.5 3.18171 0.685932 3.51267 1.01689L11 8.50422L18.4873 1.01689C18.8183 0.685932 19.2672 0.5 19.7352 0.5C20.2033 0.5 20.6521 0.685932 20.9831 1.01689C21.3141 1.34785 21.5 1.79673 21.5 2.26478C21.5 2.73283 21.3141 3.18171 20.9831 3.51267L13.4958 11L20.9831 18.4873C21.3141 18.8183 21.5 19.2672 21.5 19.7352C21.5 20.2033 21.3141 20.6521 20.9831 20.9831C20.6521 21.3141 20.2033 21.5 19.7352 21.5C19.2672 21.5 18.8183 21.3141 18.4873 20.9831L11 13.4958L3.51267 20.9831C3.18171 21.3141 2.73283 21.5 2.26478 21.5C1.79673 21.5 1.34785 21.3141 1.01689 20.9831C0.685931 20.6521 0.5 20.2033 0.5 19.7352C0.5 19.2672 0.685931 18.8183 1.01689 18.4873L8.50422 11L1.01689 3.51267C0.685931 3.18171 0.5 2.73283 0.5 2.26478C0.5 1.79673 0.685931 1.34785 1.01689 1.01689Z" fill="#D9D9D9" />
                    </svg>
                </div>

                <HomePopupHeader text="Ideapoke’s extensive coverage of CES 2025"></HomePopupHeader>
                <PopupContentWrapper>
                    <CESLogoImg src={CESLogoLarge}></CESLogoImg>
                    <PopupTextWrapper>
                        <PopupText>The CES 2025 was a roaring success!</PopupText>
                        <PopupText>Whether you missed the event or need a recap, Ideapoke has you covered with our
                            <br />
                            <PopupTextSpan>TechLens CES 2025 Report</PopupTextSpan>
                        </PopupText>
                    </PopupTextWrapper>

                    <ButtonWrapper>
                        {/* <ButtonOrangeFill onClick={HandleShowReports}>
                            Show me the reports
                        </ButtonOrangeFill> */}
                        <ButtonOrangeFill onClick={HandleShowReports}>
                            TechLens CES 2025 Report
                        </ButtonOrangeFill>
                    </ButtonWrapper>
                </PopupContentWrapper>


            </ReactModal>
        </div>
    )
}

export default HomePopup